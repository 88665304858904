<template>
   <div class="form">
    <el-form class="content bascform" ref="form" :model="form" label-width="100px" :rules="rules">
      <div class="col col4">
        <el-form-item label="序号" required prop="serialNumber">
            <el-input  v-model="form.serialNumber"></el-input>
        </el-form-item>
        <el-form-item label="评分项" required prop="name">
            <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="基准分" required prop="trueScore">
            <el-input  v-model="form.trueScore"  ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'AddscoreDialog',
  rules: {
    serialNumber: [{ required: true, message: '请输入序号', trigger: 'blur,change' }],
    name: [{ required: true, message: '请输入评分项', trigger: 'blur,change' }],
    trueScore: [{ required: true, message: '请输入基准分', trigger: 'blur,change' }]
  },
  data () {
    return {
      form: { }
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
.form{
    :deep(.el-input__inner){
        width: 80%;
    }
}
</style>
